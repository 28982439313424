import './navigation.css'
import * as React from "react";
import {NavigationType} from "./NavigationType";

export default function Navigation({children}: NavigationType) {
    return (
        <ul>
            {children}
        </ul>
    );
}