import './contact.css';
import { ReactComponent as TelegramIcon} from '../../icons/TelegramIcon.svg';
import { ReactComponent as GitIcon} from '../../icons/GitIcon.svg';

const Contact =  () => {
    return (
        <div className="contact">
            <a className='contact-link' href='https://t.me/romtuck'>
                <TelegramIcon color='#1c93e3' width={25} height={25}/>
            </a>
            <a className='contact-link' href='https://git.romtuck.ru/romtuck'>
                <GitIcon color='green' width={25} height={25}/>
            </a>
        </div>
    );
}

export default Contact;
