import Header from "../../components/Header/Header";
import Cards from "../../components/Card/Cards";
import Card from "../../components/Card/Card";
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from "react";
import about from "../../api/about";

const AboutPage =  () => {

    const [markdownText, setMarkdownText] = useState<string>("");

    useEffect(() => {
        const fetchInstruction = async () => {
            try {
                const result = await about();
                setMarkdownText(result);
            } catch (error) {
                console.error("Ошибка загрузки инструкции:", error);
            }
        };

        fetchInstruction().catch((error) => {
            console.error("Ошибка при вызове fetchInstruction:", error);
        });
    }, []);

    return (
        <>
            <Header name="Обо мне" />
            <Cards>
                <Card>
                    <ReactMarkdown>{markdownText}</ReactMarkdown>
                </Card>
            </Cards>
        </>
    );
}

export default AboutPage;
