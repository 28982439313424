import './text.css';
import React from "react";

const Text =  ({children}: {children: React.ReactNode}) => {
    return (
       <p className="text-content">
           {children}
       </p>
    );
}

export default Text;
