import { Link } from "react-router-dom";
import {ItemType} from "./ItemType";

export default function Item(
    {name, active, onClick, href}: ItemType
) {
    const className = (): string => {
        let classNameList = [];

        if (active) {
            classNameList.push('active');
        }

        return classNameList.join(' ');
    };

    return (
        <li>
            <Link
                to={href}
                className={className()}
                onClick={onClick}
            >
                {name}
            </Link>
        </li>
    );
}