import './header.css'
import {ReactNode} from "react";

export default function Header({ children, name }: { children?: ReactNode; name: string }) {
    return (
        <div className="header">
            <h1>{name}</h1>
            <p>{children}</p>
        </div>
    );
}